import { useEffect, useState } from "react";
import {BehaviorSubject, Observable} from "rxjs";

const useObservable = (
  observable: any,
  initialValue?: any
): any => {
  const [value, setValue] = useState(initialValue ?? null);

  useEffect(() => {
    const sub = observable.subscribe((e:any) => setValue(e));
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return value;
};
export default useObservable;
