const stepContainerEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "label":"",
    "number": 0,
    "config": "H",
    "stepNumber": 0,
    "textColor": "",
    "tutorial": {},
    "steps": [],
    "backgroundColor": "",
    "createdAt": "",
    "updatedAt": ""
};
export default stepContainerEntity;