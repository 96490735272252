import {BehaviorSubject} from "rxjs";
import {Add, Get, Update} from "../db/indexedDb.service";

class modeService {

    static _mode = new BehaviorSubject('light');
    static async setMode(data: string) {
        let params: any = await localStorage.getItem('parameters')
        console.log(params)
        if (params === undefined)
            await localStorage.setItem('parameters', JSON.stringify({mode: 'light', color: '#ffffff'}))
        console.log('update', data)
        await localStorage.setItem('parameters', JSON.stringify({mode: data, color: '#ffffff'}))
        params = JSON.parse(localStorage.getItem('parameters')?? "{}")
        this._mode.next(params?.mode)
    }
}
export default modeService;
