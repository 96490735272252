import {FieldError, Resolver} from "react-hook-form";
import {sameValue} from "../_formValidators/commonValidators";

export default class useFormHook {
    static hasError: any = (name: string, value: any, errors: Record<string, FieldError>, t: any) => {
        // Perform a custom validation depending on field name
        if (!value || value.length < 1) {
            return {
                ...errors,
                [name]: {
                    type: `empty-field`,
                    message: t("errors.required", {ns: "translation"})
                }
            };
        }
        return errors;
    }

     static resolver: Resolver = async (values) => {
        let errors = {};
        console.log(!sameValue(values.confirmed_password, values.password))
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => this.hasError(name, value, acc), {});
        console.log(errors)
        return {values, errors};
    };
}