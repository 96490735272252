import {BehaviorSubject} from "rxjs";
import {Feedback} from "../@types/feedback";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import feedbackEntity from "../_emptyEntity/feedback";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

class feedbackService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static feedbacks: Collection = collection;
    static _feedbacks = new BehaviorSubject<Collection>(this.feedbacks);
    static feedback: Feedback = feedbackEntity;
    static _feedback = new BehaviorSubject<Feedback>(this.feedback);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (t:any, takeNumber:number) => {
        http.get(this.baseUrl + 'feedback', {headers: {Authorization: this.token}, params: {take: takeNumber}})
            .subscribe({
                next: (e: any) => {
                    this.feedbacks = e.data["hydra:member"];
                    this._feedbacks.next(this.feedbacks);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
        // subscribe(e => {this.feedbacks = e.data["hydra:member"]})
    };
    static post = (data: Partial<Feedback>, t: any) => {

       return http.post(this.baseUrl + `feedback`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.feedback = {...feedbackEntity, ...e.data};
                    this._feedback.next(this.feedback);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("feedback", {ns: "glossary"}) + t("success.send", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined) => {
        const {t} = useTranslation()
        http.get(this.baseUrl + `feedback/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.feedback = {...feedbackEntity, ...e.data};
                    this._feedback.next(this.feedback);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };
    static delete = (id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `feedback/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("feedback", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default feedbackService;
