import {BehaviorSubject, Observable, tap} from "rxjs";
import http from "./config/http.service";
import {User} from "../@types/user";
import {Make} from "../@types/make";
import userEntity from "../_emptyEntity/user";
import {toast} from "react-toastify";
import feedbackEntity from "../_emptyEntity/feedback";

const person: any = {
    'firstName': "",
    'lastName': "",
}

const baseUser = {
    "id":"",
    "email":"",
    "password":"",
    "newPassword":"",
    "rgpd": false,
    "roles": [],
    "createdAt": "",
    "updatedAt": ""
};


class userService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static user: User = baseUser;
    static _user = new BehaviorSubject<User>(this.user);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static get = (id: string, t: any) => {
        return http.get(this.baseUrl + `users/${id}`, {withCredentials: false, headers: {Authorization: `Bearer ${this.token}`}})
            .subscribe({
                next: (e: any) => {
                    this.user = {...userEntity, ...e.data};
                    this._user.next(this.user);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });;
    };

  /*  static post(key: string, id: string): Observable<any> {
        const body: any = {};
        const cp: any = {...this.user};
        body[key] = cp[key];

        return http.post(
            `https://localhost/api/users/${id}`,
            body,
            {
                withCredentials: false,
            }
        );
    }*/
    static post(body:any) {
        return http.post(this.baseUrl + `users`, body, {withCredentials: false});
    }

    static put = (id: string | undefined, data: Partial<User>, t: any) => {
        http.post(this.baseUrl + `users/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.user = {...userEntity, ...e.data};
                    this._user.next(this.user);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("make", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };


    static saveKey(key: string, id: string): Observable<any> {
        const body: any = {};
        const cp: any = {...this.user};
        body[key] = cp[key];

        return http.put(
            this.baseUrl + `users/${id}`,
            body,
            {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
    }

    static setAttribute(name:string, value:any): void {
        console.log('update', name)
        eval("this.user." + name + "=" + value)
        (this.user as unknown as Array<any>)[name] = value
        this._user.next(this.user)
    }

    static checkEmail(email: string) {
        console.log('check-email', email)
        return  http.post(this.baseUrl + `check-email`, {'email': email}, {withCredentials: false})
    }
}

export default userService;
