// ----------------------------------------------------------------------

import {alpha} from "@mui/material/styles";
import {Theme} from "@mui/material";

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        root: {
          backgroundImage: 'none',
          //border: `4px solid ${alpha(theme.palette.primary.main, 1)}`,
          //backgroundColor:'#f3eeee'
        }
      }
    }
  };
}
