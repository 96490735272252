import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AccountCircle, Menu as MenuIcon, MoreVert, Notifications, NotificationsOutlined} from '@mui/icons-material';
import {CustomAppBar, ExpandButtonBox, MenuButton, SectionDesktop, SectionMobile} from "../../../../_styles/HeaderStyle";
import {useTheme} from "@mui/material/styles";
import NotifMenu from "./menu/NotifMenu";
import ProfileMenu from "./menu/ProfileMenu";
import {Badge, Box, IconButton, Toolbar, Typography} from "@mui/material";
import LanguagePopover from "./LanguagePopover";
import ExpandButton from "../../../common/buttons/deprecated/ExpandButton";
import MobileMenu from "./menu/MobileMenu";
import FormAddTutorial from "../../../tutorial/modal/FormAddTutorial";

const _setAPIToken = (ApiToken: string) => {
    localStorage.setItem("API_TOKEN", "bearer " + ApiToken);
};

const AppBar: React.FC<{ handleDrawerToggle: any, open: boolean }> = ({handleDrawerToggle, open}) => {
    const theme = useTheme();
    const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);
    const [notifyAnchorEl, setNotifyAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileAnchorEl, setMobileAnchorEl] = useState<null | HTMLElement>(null);
    const [reload, setReload] = useState(false);
    const isMobileOpen = Boolean(mobileAnchorEl);
    const isProfileOpen = Boolean(profileAnchorEl);
    const isNotifyOpen = Boolean(notifyAnchorEl);
    const [notifyCount, setNotifCount] = useState(0);
    const [notifyList, setNotifList] = useState([]);
    const [openTutorial, setOpenTutorial] = useState<boolean>(false);


    const {t} = useTranslation();
    const handleNotifyClick = async (event: React.MouseEvent<HTMLElement>) => {
        setNotifyAnchorEl(event.currentTarget);
        try {
            setReload(!reload);
        } catch (err) {
        }
    };
    const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
        setProfileAnchorEl(event.currentTarget);
    };
    const handleMobileClick = (event: React.MouseEvent<HTMLElement>) => {
        setMobileAnchorEl(event.currentTarget);
    };
    const handleNotifyClose = () => {
        setNotifyAnchorEl(null);
    };
    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };
    const handleMobileClose = () => {
        setMobileAnchorEl(null);
    };

    return (
        <CustomAppBar position='fixed'>
            <Toolbar>
                <Box display="flex" alignItems="center" sx={{width: '100%'}}>
                    <MenuButton
                        color="inherit"
                        aria-label="open drawer"
                        disableRipple
                        sx={{'&:hover': {background: 'transparent'}}}
                        edge="start"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon/>
                    </MenuButton>
                    <img src={"/assets/logo_white.svg"} id="logo" width="50" alt="logo"/>
                    <Box style={{marginLeft: "auto"}}>
                        <SectionDesktop>
                            <FormAddTutorial/>
                            <IconButton
                                sx={{width: "35px", height: "35px", mr: 0.75, ml: 0.75}}
                                aria-label="show notifications"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleNotifyClick}
                            >
                                <Badge badgeContent={notifyCount} color="error">
                                    {notifyCount > 0 ? (
                                        <Notifications color="secondary" sx={{fontSize: "22px"}}/>
                                    ) : (
                                        <NotificationsOutlined color="secondary" sx={{fontSize: "22px"}}/>
                                    )}
                                </Badge>
                            </IconButton>
                            <Box display="flex" alignItems="center" sx={{cursor: "pointer"}} onClick={handleProfileClick}>
                                <IconButton
                                    sx={{width: "40px", height: "40px"}}
                                    size="large"
                                    edge="end"
                                    disableRipple={true}
                                    aria-label="account of current user"
                                    onClick={handleProfileClick}
                                    color="inherit"
                                    aria-controls={isProfileOpen ? 'user-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isProfileOpen ? 'true' : undefined}
                                >
                                    <AccountCircle/>
                                    {/* <StringAvatar
                            size={30}
                            name={`${tokenData.firstName} ${tokenData.lastName}`}
                            url={profilePicture}
                        />*/}
                                </IconButton>
                                <Box sx={{ml: 2.25, mr: 1}}>
                                    <ExpandButtonBox sx={{display: "flex", alignItems: "center"}}>
                                        <Typography variant="subtitle2" fontWeight="500">
                                            {/*   {tokenData?.firstName}*/}
                                            antoine
                                        </Typography>
                                        <ExpandButton open={isProfileOpen} noMarginLeft={true} fontSize="20"/>
                                    </ExpandButtonBox>
                                    <Typography variant="body2" sx={{mt: -0.5}}>
                                        conseiller chez XXX
                                    </Typography>
                                </Box>
                            </Box>
                        </SectionDesktop>
                        <SectionMobile>
                            <IconButton
                                aria-label="show more"
                                aria-controls='mobile-menu'
                                aria-haspopup="true"
                                onClick={handleMobileClick}
                            >
                                <MoreVert sx={{color: theme.palette.secondary.light}}/>
                            </IconButton>
                        </SectionMobile>

                    </Box>
                </Box>
            </Toolbar>
            <ProfileMenu anchorEl={profileAnchorEl} isOpen={isProfileOpen} handleClose={handleProfileClose}/>
            <NotifMenu anchorEl={notifyAnchorEl} isOpen={isNotifyOpen} handleClose={handleNotifyClose} data={[]}/>
            <MobileMenu anchorEl={mobileAnchorEl} id="mobile-menu" isOpen={isMobileOpen} handleClose={handleMobileClose}
                        notifyCount={notifyCount} notifyList={notifyList}
            />
        </CustomAppBar>
    );
}
export default AppBar;
