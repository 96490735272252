import Card from "./Card";
import Lists from "./Lists";
import Paper from "./Paper";
import Input from "./Input";
import Button from "./Button";
import Tooltip from "./Tooltip";
import Backdrop from "./Backdrop";
import Typography from "./Typography";
import IconButton from "./IconButton";
import Autocomplete from "./Autocomplete";
import Table from "./Table";
import Container from "./Container";
import Slider from "./Slider";
import FormHelperText from "./FormHelperText";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: any) {
  return {
    ...Card(theme),
    ...Lists(theme),
    ...Paper(theme),
    ...Input(theme),
    ...Button(theme),
    ...Tooltip(theme),
    ...Backdrop(theme),
    ...Typography(theme),
    ...IconButton(theme),
    ...Autocomplete(theme),
    ...Table(theme),
    ...Container(theme),
    ...Slider(theme),
    ...FormHelperText(theme),
  };
}
