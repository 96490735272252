import User from "./user";

const personEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "firstName": "",
    "lastName": "",
    "pseudo": "",
    "followNumber": 0,
    "isFollowed": "",
    "user": User,
    "createdAt": "",
    "updatedAt": ""
};
export default personEntity