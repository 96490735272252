import {Navigate, useRoutes} from "react-router-dom";
import React, {lazy, Suspense} from "react";
import Loader from "../components/layout/Loader";
import FullLayout from "../layout/FullLayout";
import Account from "../pages/body/user/Account";
import Profile from "../pages/body/user/Profile";
import Help from "../pages/body/Help";

// import NoSideBarLayout from "../layout/NoSideBarLayout";
const NotFound = lazy(() => import("../pages/body/layout/NotFound"));
const TutorialCollection = lazy(() => import("../pages/body/tutorial/TutorialCollection"));
const TutorialItem = lazy(() => import("../pages/body/tutorial/TutorialItem"));
const ChannelCollection = lazy(() => import("../pages/body/channel/ChannelCollection"));
const ChannelItem = lazy(() => import("../pages/body/channel/ChannelItem"));
const MakeCollection = lazy(() => import("../pages/body/tutorial/MakeCollection"));
const MakeItem = lazy(() => import("../pages/body/tutorial/MakeItem"));
const FavoriteCollection = lazy(() => import("../pages/body/common/FavoriteCollection"));
const FollowsCollection = lazy(() => import("../pages/body/common/FollowCollection"));
const MaterialCollection = lazy(() => import("../pages/body/material/MaterialCollection"));
const HistoryCollection = lazy(() => import("../pages/body/material/MaterialCollection"));
const Dashboard = lazy(() => import("../pages/body/Dashboard"));
const CguPage = lazy(() => import("../pages/body/layout/CguPage"));

// ----------------------------------------------------------------------

const Router = () => useRoutes([
    {path: "/", element: <Navigate to="dashboard" replace/>},
    {
        path: "/",
        element: <FullLayout/>,
        children: [
            {
                path: "/dashboard",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <Dashboard/>
                    </Suspense>
                ),
            },
            {
                path: "/account",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <Account/>
                    </Suspense>
                ),
            },
            {
                path: "/profile",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <Profile/>
                    </Suspense>
                ),
            },
            {
                path: "404",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <NotFound/>
                    </Suspense>
                ),
            },
            {
                path: "help",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <Help/>
                    </Suspense>
                ),
            },
            {
                path: "/tutorials",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <TutorialCollection/>
                    </Suspense>
                ),
            },
            {
                path: "/tutorials/:id",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <TutorialItem/>
                    </Suspense>
                ),
            },
            {
                path: "/makes",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <MakeCollection/>
                    </Suspense>
                ),
            },
            {
                path: "/makes/:id",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <MakeItem/>
                    </Suspense>
                ),
            },
            {
                path: "/favorites",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <FavoriteCollection/>
                    </Suspense>
                ),
            },
            {
                path: "/follows",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <FollowsCollection/>
                    </Suspense>
                ),
            },
            {
                path: "/history",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <HistoryCollection/>
                    </Suspense>
                ),
            },
             {
                path: "/materials",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <MaterialCollection/>
                    </Suspense>
                ),
            },
            {
                path: "/channels",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <ChannelCollection/>
                    </Suspense>
                ),
            },
            {
                path: "/channels/:id",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <ChannelItem/>
                    </Suspense>
                ),
            },
            {
                path: "cgu",
                element: (
                    <Suspense fallback={<Loader/>}>
                        <CguPage/>
                    </Suspense>
                ),
            },
            {path: "*", element: <Navigate to="/404" replace/>},
        ]
    },
    {path: "*", element: <Navigate to="/404" replace/>},
])

export default Router;

/*{
         path: "/account",
         element: <NoMenuLayout/>,
         children:[
             {
                 path:"params",
                 element:<Page/>
             }
         ]
         /!*children: [
             {
                 path: "/account/params",
                 element: (
                     <Suspense fallback={<Loader/>}>
                         <Page/>
                     </Suspense>
                 ),
             },
             ]*!/},*/
/* {
     path: "/profile",
     element: <Navigate to="/app/profile/overview" replace/>,
 },*/
/* {
     path: "/profile/:tab",
     element: (
         <Suspense fallback={<Loader/>}>
             <Page/>
         </Suspense>
     ),
 },*/
/*   {
       path: "/account",
       element: <NoMenuLayout/>,
       children: [
           {
               path: "/account/params",
               element: (
                   <Suspense fallback={<Loader/>}>
                       <Page/>
                   </Suspense>
               ),
           },
           {
               path: "/account/data",
               element: (
                   <Suspense fallback={<Loader/>}>
                       <Page/>
                   </Suspense>
               ),
           },
       ],
   },*/
/* {
     path: "/",
     element:
         <NoMenuLayout/>,
     children:
         [
             {
                 path: "onboarding",
                 element: <Navigate to="/app/home" replace/>,
             },
             {
                 path: "cgu",
                 element: (
                     <Suspense fallback={<Loader/>}>
                         <Page/>
                         <CguPage/>
                     </Suspense>
                 ),
             },
             {
                 path: "404",
                 element: (
                     <Suspense fallback={<Loader/>}>
                         <NotFound/>
                     </Suspense>
                 ),
             },
             {
                 path: "/",
                 element: <Navigate to="/dashboard" replace/>
             },
             {
                 path: "*",
                 element: <Navigate to="/404" replace/>
             },
         ].filter((el: any) => el.path),
 },*/
