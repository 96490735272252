// @ts-ignore
import isEmail from "validator/lib/isEmail";
// @ts-ignore
import isEmpty from "validator/lib/isEmpty";

export const required = (value: any, controls: any) => {
  if (isEmpty(value)) {
    controls.hasError = true;
    controls.error = "errors.required";
  }
  return controls.hasError;
};

export const emailValidator = (value: any, controls: any) => {
  if (!isEmail(value)) {
    controls.hasError = true;
    controls.error = "errors.email";
  }
  return controls.hasError;
};

export const passwordValidator = (value: any) => {
  return !/^(?=[\040-\176]*?[A-Z])(?=[\040-\176]*?[a-z])(?=[\040-\176]*?[0-9])(?=[\040-\176]*?[#?!@$%^&*-/_])[\040-\176]{8,}$/.test(
    value
  );
};

export const textInputValidator = (value: any) => {
  return !/W+/.test(
      value
  );
};

export const shouldTrue = (value: any, controls: any) => {
  if (!value) {
    controls.hasError = true;
    controls.error = "errors.truthy";
  }
  return controls.hasError;
};

export const sameValue = (value: any, compareValue: any) => {
  return value === compareValue;
};
