const feedbackEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "currentUrl": "",
    "mood": 0,
    "type": "",
    "message": "",
    "person": {},
    "fullName": "",
    "createdAt": "",
    "updatedAt": ""
};
export default feedbackEntity;