// @ts-ignore
import logo from "../../logo.png";
import React from "react";
import Page from "./Page";
import {Logo} from "../../_styles/LoginStyle";
import {Stack, Typography} from "@mui/material";

const Loader = () => (
    <Stack width="100%" textAlign="center" justifyContent="center" alignItems="center">
        <Logo src={"/logo.png"} className="App-logo" alt="logo"/>
        <Typography variant='h2' color='black'>Chargement...</Typography>
    </Stack>
);

export default Loader