import React from "react";
import {Outlet} from "react-router-dom";
import NoAppBarHeader from "../pages/A_header/NoAppBarHeader";
import MobileFooter from "../pages/C_footer/MobileFooter";
import Footer from "../pages/C_footer/Footer";

export default function NoMenuLayout() {

    return (
        <>
          <NoAppBarHeader/>
            <Outlet/>
            <Footer/>
           {/* <MobileFooter/>*/}
        </>
    );
}
