import {AppBar, AppBarProps, Badge, Box, Drawer, IconButton, styled, Theme} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import {Notifications, NotificationsOutlined} from "@mui/icons-material";
import {CSSObject} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 270;

export const CustomAppBar = styled(AppBar)(({theme}: any) => ({
    boxShadow: "none",
    //backgroundColor: "rgba(244, 245, 247, 0)",
    bgcolor: 'blue',
    "&.notsmall": {
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
}));

export const MenuButton = styled(IconButton)(({theme}: any) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
       // display: "none",
    },
}));

export const SectionDesktop = styled(Box)(({theme}: any) => ({
    display: "none",
    [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
    },
}));

export const ExpandButtonBox = styled(Box)(({theme}: any) => ({
    color: theme.palette.secondary.light,
}));

export const SectionMobile = styled(Box)(({theme}: any) => ({
    display: "flex",
    [theme.breakpoints.up("sm")]: {
        display: "none",
    },
}));

export const CustomBadge = styled(Badge)({
    "& .MuiBadge-badge": {
        height: "16px",
        minWidth: "16px",
        padding: "0px 4px",
        fontSize: "12px",
    },
});

export const NotificationIcon = styled(Notifications)({
    maxWidth: "20px",
    maxHeight: "20px",
});

export const NotificationOutlinedIcon = styled(NotificationsOutlined)({
    maxWidth: "20px",
    maxHeight: "20px",
});

export const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    top:'55px',
    zIndex:'initial'
});

export const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    top: '55px',
    zIndex: 'initial'
});


export const DrawerBase = styled(Drawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        position:'absolute',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper':{},
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);