import React, {useEffect, useMemo, useState} from "react";
// material
import {CssBaseline, StyledEngineProvider} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
//
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows from "./shadows";
import modeService from "../services/mode.service";
// ----------------------------------------------------------------------

export default function ThemeConfig({children}: any) {

    const [mode, setMode] = useState<'light' | 'dark'>('light');

    useEffect(() => {
        const mode = modeService._mode.subscribe((mode: any) => {
            setMode(mode);
        });
        return () => {
            mode.unsubscribe();
        };
    }, [])

    const theme: any = useMemo(
        () =>
            createTheme({
                palette: {...palette, mode: mode},
                shape: shape,
                typography: typography,
                breakpoints,
                shadows: shadows,
            }),
        [mode],
    );

    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}