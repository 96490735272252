import {Box, BoxProps, styled} from "@mui/material";

interface StyledBoxProps extends BoxProps {
 isNotVisible?: boolean
}

export const ModalBox = styled(Box, {shouldForwardProp: (props) =>  props !== 'isNotVisible'})<StyledBoxProps>
(({theme, isNotVisible}) => ({
 position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "98vh",
  overflow: "auto",
  border: "none",
  borderRadius: "6px",
 ...!isNotVisible  && {
  backgroundColor: theme.palette.background.paper,
 },
  boxShadow: "24px",
  padding: "25px 15px",
  [theme.breakpoints.down('xs')]: {
    //     backgroundColor: purple[500],
  //  width: "95%",
  },
  [theme.breakpoints.down('sm')]: {
    //     backgroundColor: purple[500],
   // width: "80%",
  },
  [theme.breakpoints.up('sm')]: {
    // maxWidth: "90%",
  //  width: "70%",
  },
  [theme.breakpoints.up('md')]: {
   //width: "60%",
  },
  [theme.breakpoints.up('lg')]: {
  },
}));

export const hidden = {
    backgroundColor : 'transparent'
}