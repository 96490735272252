export const costs = [
    {value: "inexpensive", label: "cost.inexpensive"},
    {value: "normal", label: "cost.normal"},
    {value: "expensive", label: "cost.expensive"}
];
export const difficulties = [
    {value: "easy", label: "difficulty.easy"},
    {value: "medium", label: "difficulty.medium"},
    {value: "hard", label: "difficulty.hard"}
];
export const PEGIs = [
    {value: "threeYearsOld", label: "PEGI.threeYearsOld"},
    {value: "sevenYearsOld", label: "PEGI.sevenYearsOld"},
    {value: "twelveYearsOld", label: "PEGI.twelveYearsOld"},
    {value: "sixteenYearsOld", label: "PEGI.sixteenYearsOld"},
    {value: "heighteenYearsOld", label: "PEGI.heighteenYearsOld"}
];