import React from "react";
import {useTranslation} from "react-i18next";
import CheckInputValid from "./deprecated/CheckValidInput";
import TextInput from "./TextInput";
import isEmail from "validator/lib/isEmail";

const EmailInput: React.FC<{
    name: string,
    email: any,
    required?: boolean,
    sx?: any;
    error?: any,
    helperText?: any,
    disabled?: boolean,
    register?: any,
    value?: string
}>
    = ({
           name,
           email,
           required,
           sx,
           error,
           helperText,
           disabled,
           register,
           value
       }) => {
    const {t} = useTranslation();
    return (
        <TextInput
            name={name}
            label={t(`user.mail${required ? "_required" : ""}`, {ns: "form/formLabel"})}
            type="email"
            autoComplete="username"
            placeholder="john.doe@mail.com"
            sx={sx}
            helperText={error?.message}
            error={error}
            endAdornment={(!error && isEmail(email) && <CheckInputValid/>)}
            disabled={disabled}
            register={register}
            required={required}
            value={value}
        />
    );
};

export default EmailInput;
