import { InputAdornment, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";

const CustomCheckIcon = styled(CheckIcon)(({ theme }: any) => ({
  fill: theme.palette.success.main,
}));

const CheckInputValid = () => {
  return (
    <InputAdornment position="end">
      <CustomCheckIcon />
    </InputAdornment>
  );
};
export default CheckInputValid;
