import {BehaviorSubject, Observable} from "rxjs";
import {Tutorial} from "../@types/tutorial";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import tutorialEntity from "../_emptyEntity/tutorial";
import collection from "../_emptyEntity/Collection";
import makeService from "./make.service";
import {withTranslation} from 'react-i18next';
import {toast} from "react-toastify";

class tutorialService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static tutorials: Collection = collection;
    static _tutorials = new BehaviorSubject<Collection>(this.tutorials);
    static tutorial: Tutorial = tutorialEntity;
    static _tutorial = new BehaviorSubject<Tutorial>(this.tutorial);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (t: any, takeNumber?: number) => {
        console.log('tuto token', this.token)
        http.get(this.baseUrl + 'tutorials', {headers: {Authorization: this.token}, params: {take: takeNumber}})
            .subscribe({
                next: (e: any) => {
                    this.tutorials = e.data["hydra:member"];
                    this._tutorials.next(this.tutorials);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };
    static post = (data: Partial<Tutorial>, navigate: any, t: any) => {
        http.post(this.baseUrl + `tutorials`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.tutorial = {...tutorialEntity, ...e.data};
                    this._tutorial.next(this.tutorial);
                    navigate(`/tutorials/${e.id}`)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("tutorial", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t:any) => {
        http.get(this.baseUrl + `tutorials/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.tutorial = {...tutorialEntity, ...e.data};
                    this._tutorial.next(this.tutorial);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };

    static put = (id: string | undefined, data: Partial<Tutorial>, t: any) => {
        http.post(this.baseUrl + `tutorials/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.tutorial = {...tutorialEntity, ...e.data};
                    this._tutorial.next(this.tutorial);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("tutorial", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `tutorials/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("tutorial", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };

    static saveKey(key: string, id: string): Observable<any> {
        const body: any = {};
        const cp: any = {...this.tutorial};
        body[key] = cp[key];

        return http.put(
            `https://localhost/api/tutorials/${id}`,
            body,
            {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
    }

    static incrementTarget(id: string, variableName: string[]) {
        // @ts-ignore
        this.tutorial = {...this.tutorial, [variableName[0]]: this.tutorial[variableName[0]] + 1 , [variableName[1]]: id}
        this._tutorial.next(this.tutorial);
    }
    static decrementTarget(variableName: string[]) {
        // @ts-ignore
        this.tutorial = {...this.tutorial , [variableName[0]]: this.tutorial[variableName[0]] - 1, [variableName[1]]: false};
        this._tutorial.next(this.tutorial);
    }
}

export default tutorialService;
