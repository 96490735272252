import {Apps, ManageAccounts, Notifications, NotificationsOutlined, Person, Settings,} from "@mui/icons-material";
import {Badge, Box, Divider, Menu, MenuItem, styled, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import NotifMenu from "./NotifMenu";

import axios from "axios";
import {CustomBadge, NotificationIcon, NotificationOutlinedIcon} from "../../../../../_styles/HeaderStyle";
import LogoutButton from "../../../../auth/LogoutButton";

const MobileMenu: React.FC<{anchorEl: any, id?: any, isOpen: any, handleClose: any, tokenData?: any, context?: any, avatar?: any, notifyCount?: any, notifyList:any }>
    = ({anchorEl, id, isOpen, handleClose, tokenData, context, avatar, notifyCount, notifyList,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [notifyAnchorEl, setNotifyAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const isNotifyOpen = Boolean(notifyAnchorEl);
  const [contextList, setContextList] = useState<object[]>([]);
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const handleProfileRedirection = () => {
    const profileLocation = "/app/profile/overview";
    navigate(profileLocation, {
      replace: location.pathname === profileLocation,
    });
    handleClose();
  };

  const handleRedirect = (redirect: string) => {
    navigate(`/app/${redirect}`);
    handleClose();
  };

  const handleNotifMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotifyAnchorEl(event.currentTarget);
  };

  const handleNotifyClose = () => {
    setNotifyAnchorEl(null);
  };
  return (
    <Menu
      sx={{ mt: 5 }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={id}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      onClose={handleClose}
    >
      <Box display="flex" alignItems="center" sx={{ m: 2 }}>
       {/* <StringAvatar
          size={40}
          name={`${tokenData.firstName} ${tokenData.lastName}`}
          url={avatar}
        />*/}
        <Typography variant="h3" sx={{ ml: 1 }}>
        {/*  {tokenData?.firstName}*/}
          Antoine
        </Typography>
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }} />
      {(context === "collab" ||
        context === "benef" ||
        context === "candidate") && (
        <MenuItem sx={{ m: 1 }} onClick={handleProfileRedirection}>
          <Person />
          <Typography
            variant="body1"
            color={location.pathname.includes("profile") ? "primary" : ""}
            sx={{
              ml: 2,
              fontWeight: "600",
              opacity: location.pathname.includes("profile") ? "1" : "0.5",
            }}
          >
            {t("profile_menu.profile", {ns: "header/appBar"})}
          </Typography>
        </MenuItem>
      )}
      <MenuItem sx={{ m: 1 }} onClick={handleNotifMenuOpen}>
        <CustomBadge badgeContent={notifyCount} color="error">
          {notifyCount > 0 ? (
            <NotificationIcon
              color={
                location.pathname.includes("notification")
                  ? "primary"
                  : "secondary"
              }
              sx={{
                opacity: location.pathname.includes("notification")
                  ? "1"
                  : "0.5",
              }}
            />
          ) : (
            <NotificationOutlinedIcon
              color={
                location.pathname.includes("notification")
                  ? "primary"
                  : "secondary"
              }
              sx={{
                opacity: location.pathname.includes("notification")
                  ? "1"
                  : "0.5",
              }}
            />
          )}
        </CustomBadge>
        <Typography
          variant="body1"
          color={location.pathname.includes("notification") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight: "600",
            opacity: location.pathname.includes("notification") ? "1" : "0.5",
          }}
        >
          {t("notification.title", {ns: "utils/notification"})}
        </Typography>
      </MenuItem>
      {/* <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("account/data")}>
        <DataIcon active={location.pathname.includes("data")} />
        <Typography
          variant="body1"
          color={location.pathname.includes("data") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight: "600",
            opacity: location.pathname.includes("data") ? "1" : "0.5",
          }}
        >
          {t("profile_menu.data")}
        </Typography>
      </MenuItem> */}
      {((tokenData?.roles.includes("ROLE_ADMINADVISOR") &&
        context === "advisor") ||
        (tokenData?.roles.includes("ROLE_ADMINRECRUITER") &&
          context === "recruiter")) && (
        <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("params/home")}>
          <Settings />
          <Typography
            variant="body1"
            color={location.pathname.includes("app/params") ? "primary" : ""}
            sx={{
              ml: 2,
              fontWeight: "600",
              opacity: location.pathname.includes("app/params") ? "1" : "0.5",
            }}
          >
            {t("profile_menu.admin")}
          </Typography>
        </MenuItem>
      )}
      <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("account/params")}>
        <ManageAccounts />
        <Typography
          variant="body1"
          color={location.pathname.includes("account/params") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight: "600",
            opacity: location.pathname.includes("account/params") ? "1" : "0.5",
          }}
        >
          {t("profile_menu.params", {ns: "header/appBar"})}
        </Typography>
      </MenuItem>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ pl: 1, pr: 1, mt: 2 }}>
        {contextList?.length > 1 && (
          <>coucou</>
        )}
      </Box>
      <Box sx={{ m: 1 }}>
       {/* <LogoutButton />*/}
      </Box>
      {isNotifyOpen && (
        <NotifMenu
          anchorEl={notifyAnchorEl}
          isOpen={isNotifyOpen}
          handleClose={handleNotifyClose}
          data={notifyList}
        />
      )}
    </Menu>
  );
};

export default MobileMenu;
