import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {PageStyles} from '../../_styles/PageStyles'
import {LayoutContext} from "../../layout/FullLayout";

const Page:React.FC<{children: any, title: any, sx?: any, other?: any, ref?: any}> = ({children, title, sx, other, ref}) => {
    const {width} = useContext(LayoutContext);
    return(
            <PageStyles
                sx={{ml:width,...sx}}
                ref={ref}
                {...other}
            >
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {children}
            </PageStyles>
    );
}

export default Page;
