import {ListItem, ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import React, {useEffect, useState} from "react";
// import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import LogoutModal from "./logoutModal/LogoutModal";
import LogoutIcon from "@mui/icons-material/Logout";

const LogoutButton: React.FC<{ padding?: any, className?: any }> = ({padding, className}: any) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <>
            <MenuItem onClick={() => setOpen(true)} className={className}>
                <ListItemIcon sx={{pl: padding}}>
                    <LogoutIcon/>
                </ListItemIcon>
                <ListItemText primary={t("logout.logout", {ns: "header/appBar"})}/>
            </MenuItem>
            {open && <LogoutModal open={open} close={() => setOpen(false)}/>}
        </>
    );
}

export default LogoutButton;