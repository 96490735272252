import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import React from "react";

const SelectInput: React.FC<{
    name: string
    value: string
    label: any
    error: any
    register: any
    helperText: any
    children: any
    onChange?: any
    classesFormControl?: object
    colorFormControl?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
    component?: any
    disabledFormControl?: boolean
    focused?: boolean
    fullWidth?: boolean
    hiddenLabel?: boolean
    margin?: 'dense' | 'none' | 'normal'
    requiredFormControl?: boolean
    sizeFormControl?: 'medium' | 'small' | undefined
    sxFormControl?: Array<object | boolean> | object
    variantFormControl?: 'filled' | 'outlined' | 'standard'
    autoWidth?: boolean
    classesSelect?: object
    defaultOpen?: boolean
    defaultValue?: any
    disabled?: boolean
    displayEmpty?: boolean
    iconComponent?: any
    id?: string
    input?: any
    inputProps?: object
    MenuProps?: object
    multiple?: boolean
    native?: boolean
    onClose?: any
    onOpen?: any
    open?: boolean
    renderValue?: any
    required?: boolean
    selectDisplayProps?: object
    sxSelect?: Array<object> | object
    variantSelect?: 'filled' | 'outlined' | 'standard'
}> = ({
          children,
          classesFormControl,
          colorFormControl,
          disabledFormControl,
          error,
          focused,
          fullWidth,
          hiddenLabel,
          margin,
          requiredFormControl,
          sizeFormControl,
          sxFormControl,
          variantFormControl,
          autoWidth,
          classesSelect,
          defaultOpen,
          defaultValue,
          disabled,
          displayEmpty,
          iconComponent,
          helperText,
          id,
          input,
          inputProps,
          label,
          MenuProps,
          multiple,
          name,
          native,
          onChange,
          onClose,
          onOpen,
          open,
          renderValue,
          required= false,
          selectDisplayProps,
          sxSelect,
          value,
          variantSelect,
          register
      }) => {
    return (
        <FormControl
            classes={classesFormControl}
            color={colorFormControl}
            disabled={disabledFormControl}
            error={Boolean(error)}
            focused={focused}
            fullWidth={fullWidth}
            hiddenLabel={hiddenLabel}
            margin={margin}
            required={requiredFormControl}
            size={sizeFormControl}
            sx={sxFormControl}
            variant={variantFormControl}
        >
            <InputLabel id={"select-" + label} htmlFor={"standard-adornment-" + label}>{label}</InputLabel>
            <Select
                autoWidth={autoWidth}
                classes={classesSelect}
                defaultOpen={defaultOpen}
                defaultValue={defaultValue}
                displayEmpty={displayEmpty}
                IconComponent={iconComponent}
                id={id}
                input={input}
                inputProps={inputProps}
                label={label}
                labelId={"select-" + label}
                MenuProps={MenuProps}
                multiple={multiple}
                native={native}
                onChange={onChange}
                onClose={onClose}
                onOpen={onOpen}
                open={open}
                renderValue={renderValue}
                SelectDisplayProps={selectDisplayProps}
                sx={sxSelect}
                value={value}
                variant={variantSelect}
                {...register(name, {required: required})}
            >
                {children}
            </Select>
            <FormHelperText>{helperText?.message}</FormHelperText>
        </FormControl>
    );
};
export default SelectInput;