import axios, { AxiosRequestConfig } from "axios";
import {
  AxiosObservable,
  createAxiosObservable,
} from "./createAxiosObservable";

  axios.interceptors.response.use(
      (response) => {
   // console.log("interceptor response",response);
    return response;
    },
(error) => {
        console.log('Error',error)
  const {status} = error.response;
  console.log("interceptor error", error.response.status);
 // console.log("interceptor status", status);
  switch (status) {
    case 400:
      console.log("ERROR 400");
      break;
    case 401:
      console.log("ERROR 401");
    if(!window.location.pathname.includes("login"))
      return Promise.reject(JSON.stringify(error?.response ?? error));
      localStorage.clear()
      window.location.href = '/login';
      break;
    case 403:
      console.log("ERROR 403");
      break;
    case 404:
      console.log("ERROR 404");
     break;
    case 500:
      console.log("ERROR 500");
      break;
    default:
      break;
  }
  //return Promise.reject(error?.response ?? error);
}
)

class http {

  static get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createAxiosObservable<T>(axios.get, url, config);
  }

  static post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createAxiosObservable<T>(axios.post, url, data, config);
  }

  static put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createAxiosObservable<T>(axios.put, url, data, config);
  }

  static patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createAxiosObservable<T>(axios.patch, url, data, config);
  }

  static delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createAxiosObservable<T>(axios.delete, url, config);
  }
}

export default http;
