import * as React from 'react';
import {useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Button, IconButton, ListItem} from "@mui/material";
import {DrawerBase} from "../../../_styles/HeaderStyle";
import {useNavigate} from "react-router-dom";
import {Favorite, Handyman, History, ReceiptLong, School, Subscriptions, Twitter, SyncAlt} from "@mui/icons-material";
import {AdminContext} from "../../../App";

const defaultMenu = [
    {label: "tutorials", icon: <School/>},
    {label: "makes", icon: <Handyman/>},
    {label: "favorites", icon: <Favorite/>},
    {label: "follows", icon: <Twitter/>},
    {label: "channels", icon: <Subscriptions/>},
    {label: "Materials", icon: <ReceiptLong/>},
    {label: "History", icon: <History/>},
];

const adminMenu = [
    {label: "dashboard", icon: <School sx={{color: '#9d62de'}}/>},
    {label: "tutorials", icon: <School sx={{color: '#9d62de'}}/>},
    {label: "makes", icon: <Handyman sx={{color: '#9d62de'}}/>},
    {label: "channels", icon: <Subscriptions sx={{color: '#9d62de'}}/>},
    {label: "materials", icon: <ReceiptLong sx={{color: '#9d62de'}}/>},
    {label: "feedbacks", icon: <Favorite sx={{color: '#9d62de'}}/>},
    {label: "reports", icon: <Twitter sx={{color: '#9d62de'}}/>},
    {label: "persons", icon: <History sx={{color: '#9d62de'}}/>},
];

const Drawer: React.FC<{ handleDrawerToggle: any, open: boolean }> = ({handleDrawerToggle, open}) => {
    const theme = useTheme();
    const {isAdmin, setIsAdmin} = useContext(AdminContext)
    const navigate = useNavigate();
    const menu = (isAdmin) ? adminMenu : defaultMenu
    const onclick = (label: string) => {
        navigate(label)
    }

    console.log(isAdmin)

    return (
        <DrawerBase variant="permanent" open={open} sx={{color: 'purple'}}>
            <List>
                {menu.map((item, index) => (
                    <ListItem key={item.label} disablePadding sx={{display: 'block'}}>
                        <ListItemButton onClick={() => onclick(item.label)}
                                        sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}}>
                            <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.label} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                <ListItemButton sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}}
                                onClick={() => setIsAdmin(!isAdmin)}>
                    <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}} ><SyncAlt/></ListItemIcon>
                    <ListItemText primary="switch context" sx={{opacity: open ? 1 : 0}}/>
                </ListItemButton>
            </List>
        </DrawerBase>
    );
}
export default Drawer;