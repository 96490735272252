import React, {useContext, useTransition} from "react";
import {Button, Modal, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ModalBox} from "../../../_styles/ModalStyle";
import {LoginContext} from "../../../App";
import utilsService from "../../../services/utils.service";

const LogoutModal: React.FC<{ open: boolean, width?: string, close: () => void }>
    = ({open, close, width = "510px"}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();
    const {setIsLogged} = useContext(LoginContext);


    const handleLoggingOut = async () => {
            startTransition(() => {
                localStorage.clear()
                setIsLogged(false);
                close()
                navigate('/login')
            });
    }

    return (
        <Modal open={open} onClose={close}>
            <ModalBox>
                <Stack spacing={2}>
                    <Typography variant="subtitle1">
                        {t("logout.want_to_logout", {ns: "header/appBar"})}
                    </Typography>
                    {isPending ? " Loading..." : <Stack direction="row" justifyContent="space-evenly">
                        <Button size="large" onClick={handleLoggingOut} variant="contained">
                            {t("logout.log_me_out", {ns: "header/appBar"})}</Button>
                        <Button size="large" onClick={close} variant="outlined">
                            {t("logout.keep_me_connected", {ns: "header/appBar"})}</Button>
                    </Stack>
                    }
                </Stack>
            </ModalBox>
        </Modal>
    );
};

export default LogoutModal;
