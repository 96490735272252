import {Box, FormHelperText, styled} from "@mui/material";
import {blue, green, red} from "@mui/material/colors";

export const TextLink = styled(FormHelperText)(({theme}) => ({
    cursor: "pointer",
    display: "inline-block",
    position: "relative",
    textDecoration: "none",
    fontSize: "13px",
    "&:after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "1px",
        bottom: "0",
        left: "0",
        backgroundColor: theme.palette.text.secondary,
        transform: "scaleX(0)",
        transition: "transform 0.25s ease-out",
        transformOrigin: "bottom right",
    },
    "&:hover:after": {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
    },
}));
export const Root = styled(Box)(({theme}) => ({
    display: "flex",
    borderRadius:'10px',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
   //     backgroundColor: purple[500],
        width: "90%",
    },
    [theme.breakpoints.up('sm')]: {
       // maxWidth: "90%",
        backgroundColor: red[500],
    },
    [theme.breakpoints.up('md')]: {
        backgroundColor: blue[500],
    },
    [theme.breakpoints.up('lg')]: {
        backgroundColor: green[500],
    },
}));

export const Logo = styled("img")(({theme}: any) => ({
    [theme.breakpoints.down('sm')]: {
        width: "200px",
        borderRadius:"50%"
    },
    [theme.breakpoints.up('sm')]: {
        width: "200px",
        borderRadius: "50%"
    },
    [theme.breakpoints.up('md')]: {
        width: "200px",
        borderRadius: "50%"
    },
    [theme.breakpoints.up('lg')]: {
    },
}));