import React from "react";
import {CustomFormControlLabel, CustomRadio} from "../../../_styles/FormInputStyle";

const RadioInput: React.FC<{
    name: string,
    value: any
    label: any
    register: any,
    checkedFormControlLabel?: boolean
    classesFormControlLabel?: object
    componentsProps?: { typography?: object }
    disabledFormControlLabel?: boolean
    disableTypography?: boolean
    inputRefFormControlLabel?: any
    labelPlacement?: 'bottom' | 'end' | 'start' | 'top' | undefined
    onChangeFormControlLabel?: any
    slotProps?: { typography?: object }
    sxFormControlLabel?: Array<object | boolean> | object,
    checkedRadio?: boolean,
    checkedIcon?: any,
    classesRadio?: object,
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
    disabledRadio?: boolean,
    disableRipple?: boolean,
    icon?: any,
    id?: string,
    inputProps?: object,
    inputRefRadio?: any,
    required?: boolean,
    size?: 'medium' | 'small' | undefined,
    sx?: Array<object | boolean> | object,
    valueRadio?: string,
}>
    = ({
           checkedFormControlLabel,
           classesFormControlLabel,
           componentsProps,
           disabledFormControlLabel,
           disableTypography,
           inputRefFormControlLabel,
           label,
           labelPlacement,
           onChangeFormControlLabel,
           slotProps,
           sxFormControlLabel,
           value,
           checkedRadio,
           checkedIcon,
           classesRadio,
           color,
           disabledRadio,
           disableRipple,
           icon,
           id,
           inputProps,
           inputRefRadio,
           name,
           required = false,
           size,
           sx,
           valueRadio,
           register,
       }) => {

    return (
        <CustomFormControlLabel
            value={value}
            checked={checkedFormControlLabel}
            classes={classesFormControlLabel}
            componentsProps={componentsProps}
            disabled={disabledFormControlLabel}
            disableTypography={disableTypography}
            inputRef={inputRefFormControlLabel}
            label={label}
            labelPlacement={labelPlacement}
            onChange={onChangeFormControlLabel}
            slotProps={slotProps}
            sx={sxFormControlLabel}
            control={
                <CustomRadio  {...register(name, {required: required})}
                              checked={checkedRadio}
                              disabled={disabledRadio}
                              color={color}
                              checkedIcon={checkedIcon}
                              classes={classesRadio}
                              disableRipple={disableRipple}
                              icon={icon}
                              id={id}
                              inputProps={inputProps}
                              inputRef={inputRefRadio}
                              size={size}
                              sx={sx}
                              value={valueRadio}
                />
            }
        />
    );
}

export default RadioInput;
