const ratingEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "value":0,
    "content": "",
    "targetIri": "",
    "target": {},
    "person": {},
    "createdAt": "",
    "updatedAt": ""
};
export default ratingEntity;