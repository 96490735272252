import React, {createContext, Suspense, useEffect, useState} from "react";
import "./App.css";
import {Box} from "@mui/material";
import NoAuthRoutes from "./routes/noAuthRoutes";
import AdminRoutes from "./routes/adminRoutes";
import Routes from "./routes/routes";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {BrowserRouter as Router} from "react-router-dom";
import "./i18n";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ThemeConfig from "./theme";
import Loader from "./components/layout/Loader";
import Person from "./_emptyEntity/person";
import ModeService from "./services/mode.service";

export const LoginContext = createContext({
    isLogged: false,
    setIsLogged: (isLogged: boolean) => {
    }
})
export const AdminContext = createContext({
    isAdmin: false,
    setIsAdmin: (isAdmin: boolean) => {
    }
})

export const PersonContext = createContext({
    currentPerson: Person,
    setPerson: (person: boolean) => {
    }
})

function App() {
    const [isLogged, setIsLogged] = useState<boolean>(Boolean(localStorage.getItem('isLogged')));
    const [isAdmin, setIsAdmin] = useState<boolean>(Boolean(localStorage.getItem('isAdmin')));
    const [currentPerson, setPerson] = useState<any>(JSON.parse(localStorage.getItem('person')?? "{}"));
    const loggedValue = {isLogged, setIsLogged};
    const adminValue = {isAdmin, setIsAdmin};
    const personValue = {currentPerson, setPerson};

    useEffect(() => {
        ModeService.setMode(JSON.parse(localStorage.getItem('parameters') ?? "{}")?.mode)
    }, []);

    const getContextRouting = () => {
        console.log('isLogged', isLogged)
        if (isLogged) {
            if (isAdmin)
                return (<AdminRoutes/>)
            return <Routes/>
        } else {
            return <NoAuthRoutes/>;
        }
    };

    return (
        <ThemeConfig>
            <Router>
                <HelmetProvider>
                    <Helmet/>
                    <Box component="main">
                        <LoginContext.Provider value={loggedValue}>
                            <AdminContext.Provider value={adminValue}>
                                <PersonContext.Provider value={personValue}>
                                    <Suspense fallback={<Loader/>}>
                                        {getContextRouting()}
                                    </Suspense>
                                </PersonContext.Provider>
                            </AdminContext.Provider>
                        </LoginContext.Provider>
                    </Box>
                    <ToastContainer
                        hideProgressBar
                        pauseOnHover
                        autoClose={5000}
                    />
                </HelmetProvider>
            </Router>
        </ThemeConfig>
    );
}

export default App

