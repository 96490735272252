import React from 'react';
import PasswordInput from "../common/formInputs/PasswordInput";
import {Stack} from "@mui/material";

const Password: React.FC<{ register: any, value:string, errors: any }> = ({register, value, errors}) => {
    return (<Stack direction='column' sx={{width: '100%'}}>
            <PasswordInput name='password' required register={register} error={errors.password} showRules value={value} />
            <PasswordInput name='confirmed_password' required register={register} error={errors.confirmed_password}/>
        </Stack>
    );
};

export default Password;
