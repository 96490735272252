import React from 'react';
import EmailInput from "../common/formInputs/EmailInput";
import {Stack} from "@mui/material";

const Email: React.FC<{ register: any, email: string, value?: string, errors: any }>
    = ({email, register, value, errors}) => {
    return (
        <Stack sx={{width: '100%'}}>
            <EmailInput name='email' email={email} value={value} register={register} required error={errors.email}/>
        </Stack>
    );
};

export default Email;
