import React, {createContext, useState} from "react";
// @ts-ignore
import {styled} from "@mui/material/styles";
import {Outlet} from "react-router-dom";
import Header from "../pages/A_header/Header";
import Footer from "../pages/C_footer/Footer";

const Root = styled("div")({ display: "flex", height: "100%" });
const Content = styled("main")({
  width: "100%",
  backgroundColor: "white",
});
export const LayoutContext = createContext({
    width: "",
    setWidth: (width: string) => {}
})

const FullLayout:React.FC = () => {
    const [width, setWidth] = useState("64px");
    const value = {width, setWidth};

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
        <LayoutContext.Provider value={value}>
      <Header />
        {/*{isLogged && (
            <CustomDrawer
                container={container}
                theme={theme}
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                closeDrawer={closeDrawer}
            />
        )}*/}

          {/*<TopBarContainer
              isLogged={isLogged}
              handleDrawerToggle={handleDrawerToggle}
          />*/}
          <Outlet/>
        </LayoutContext.Provider>
    </>
  );
}

export default FullLayout;
