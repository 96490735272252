import {Box, styled} from "@mui/material";

export const PageStyles = styled(Box)(() => ({
    minHeight: '100vh',
    display:"flex",
    justifyItems:"center",
    background: 'linear-gradient(45deg, rgba(85, 206, 248, 0.15) 0%, rgba(157, 98, 222, 0.15) 100%)',
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "80px",
    paddingBottom: "10px",
    justifyContent:"center"
}));