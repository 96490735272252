import {Box, IconButton} from "@mui/material";
import LanguagePopover from "../../components/layout/header/appBar/LanguagePopover";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import modeService from "../../services/mode.service";
import useObservable from "../../hooks/useObservable";
import ModeService from "../../services/mode.service";
import Chat from "../../components/chatbot/Chat";

export default function NoAppBarHeader ()
{
    const theme = useTheme();
    const [mode, setMode] = useState<'light' | 'dark'>(JSON.parse(localStorage.getItem('parameters')??"{}")?.mode?? 'light');

    const colorMode = () => {
        const newMode = mode === 'light' ? 'dark' : 'light'
        setMode(newMode);
        modeService.setMode(newMode);
    };

    return(
    <Box sx={{width: "fit-content", position: "absolute", top: 8, right: 12, display:"flex", flexDirection:"row"}}>
        <LanguagePopover/>
            <IconButton sx={{ml: 1}} onClick={colorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
            </IconButton>
    </Box>
    );
}