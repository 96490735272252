import React from "react";
import {CustomFormControl, CustomRadioGroup} from "../../../_styles/FormInputStyle";
import {FormHelperText, Stack} from "@mui/material";

const RadioGroupInput: React.FC<{
    children: any
    ariaLabelledBy: string
    helperText: any
    error: any
    name: string
    classesFormControl?: object
    colorFormControl?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
    component?: any
    disabledFormControl?: boolean
    focused?: boolean
    fullWidth?: boolean
    hiddenLabel?: boolean
    margin?: 'dense' | 'none' | 'normal'
    requiredFormControl?: boolean
    sizeFormControl?: 'medium' | 'small' | undefined
    sxFormControl?: Array<object | boolean> | object
    variant?: 'filled' | 'outlined' | 'standard'
    defaultValue?: any
    onChangeRadioGroup?: any
    valueRadioGroup?: any
    row?: boolean
}>
    = ({
           children,
           classesFormControl,
           colorFormControl,
           disabledFormControl,
           error,
           focused,
           fullWidth,
           hiddenLabel,
           margin,
           requiredFormControl,
           sizeFormControl,
           sxFormControl,
           variant,
           defaultValue,
           name,
           onChangeRadioGroup,
           valueRadioGroup,
           row,
           ariaLabelledBy,
           helperText
       }) => {

    return (
        <CustomFormControl
            classes={classesFormControl}
            color={colorFormControl}
            disabled={disabledFormControl}
            error={Boolean(error)}
            focused={focused}
            fullWidth={fullWidth}
            hiddenLabel={hiddenLabel}
            margin={margin}
            required={requiredFormControl}
            size={sizeFormControl}
            sx={sxFormControl}
            variant={variant}
        >
            <CustomRadioGroup
                row={row}
                aria-labelledby={ariaLabelledBy}
                name={name}
                defaultValue={defaultValue}
                onChange={onChangeRadioGroup}
                value={valueRadioGroup}
            >
                <Stack direction={{xs: 'column', sm: 'row'}} sx={{width: '100%'}} spacing={1}
                       justifyContent='space-evenly' alignItems='center'>
                    {children}
                </Stack>
            </CustomRadioGroup>
            {
                <FormHelperText>{helperText?.message}</FormHelperText>
            }
        </CustomFormControl>
    );
};

export default RadioGroupInput;
