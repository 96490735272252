import {Button, styled} from "@mui/material";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";

export const CustomButton = styled(Button)({
    width:'100%',
    height: "60px",
    borderRadius: "10px",
    fontSize: "16px",
});

export const BeforeButton = styled(NavigateBefore)(() => ({
    width: '15%',
    height: '100%'
}));
export const NextButton = styled(NavigateNext)(() => ({
    width: '15%',
    height: '100%'
}));