import {
    SentimentDissatisfied,
    SentimentNeutral,
    SentimentSatisfiedAlt,
    SentimentVeryDissatisfied, SentimentVerySatisfied
} from "@mui/icons-material";

export const moods = [
    {value: 1, label: <SentimentVeryDissatisfied/>},
    {value: 2, label: <SentimentDissatisfied/>},
    {value: 3, label: <SentimentNeutral/>},
    {value: 5, label: <SentimentSatisfiedAlt/>},
    {value: 6, label: <SentimentVerySatisfied/>}
];

export const types = [
    {value: "compliment", label: "type.compliment"},
    {value: "suggestion", label: "type.suggestion"},
    {value: "worry", label: "type.worry"},
    {value: "other", label: "type.other"},
];