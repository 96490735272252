import {styled} from "@mui/material/styles";
import {ExpandMore} from "@mui/icons-material";
import React from "react";

const ExpandMoreButton = styled(ExpandMore, {
        shouldForwardProp: (props) => props !== "noMarginLeft" && props !== "open"
    })
    < {noMarginLeft: boolean| undefined, open: boolean} > (({theme, noMarginLeft, open}) => ({
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            marginLeft: noMarginLeft ? "" : "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        })
    );

const ExpandButton: React.FC<{ open: boolean; noMarginLeft?: boolean; fontSize?: string }>
    = ({open, noMarginLeft, fontSize}) => {
    return (
        <ExpandMoreButton
            sx={{fontSize: `${fontSize}px` ?? ""}}
            noMarginLeft={noMarginLeft}
            open={open}
        />
    );
};
export default ExpandButton;
