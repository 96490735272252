import React, {startTransition, useState, useTransition} from "react";
import {Box, Button, Fab, IconButton, Modal, Stack, Tooltip, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useFormHook from '../../../hooks/FormHook'
import tutorialService from "../../../services/tutorial.service";
import TextInput from "../../common/formInputs/TextInput";
import {CustomButton} from "../../../_styles/ButtonStyle";
import {Tutorial} from "../../../@types/tutorial";
import RadioGroupInput from "../../common/formInputs/RadioGroupInput";
import RadioInput from "../../common/formInputs/RadioInput";
import {costs, difficulties, PEGIs} from "../../../_data/TutorialData";
import {ModalBox} from "../../../_styles/ModalStyle"
const FormAddTutorial = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [loading, startTransition] = useTransition();

    const resolver: Resolver<Tutorial> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, formState: {errors}} = useForm<Tutorial>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<Tutorial> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                tutorialService.post(data, navigate, t)
            })
        }
        handleClose()
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
            <Tooltip title="Ajouter un tutoriel" placement="left" >
            <Button color="inherit" variant="outlined" onClick={() => setOpen(!open)} sx={{mx: 1.75}}>
                <AddIcon/>
            </Button>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalBox>
                    <Stack direction="row" justifyContent="flex-end">
                    <IconButton onClick={handleClose} style={{fontSize: "normal"}}>
                        <CloseIcon fontSize="large"/>
                    </IconButton>
                    </Stack>
                    <Typography id="modal-modal-title" variant="h3" component="h2" style={{textAlign: "center"}}>
                        {t("add", {ns: "glossary"}) + ' '
                            + t("un", {ns: "glossary"}) + ' '
                            + t("tutorial", {ns: "glossary"}).toLowerCase()}
                    </Typography>
                    <Box
                        component="form"
                        sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack  spacing={2} direction='column' sx={{width: '100%'}}>
                            <TextInput name='title' register={register} required
                                       helperText={errors?.title?.message} label={t("common.title", {ns: "form/formLabel"})}/>
                            <TextInput name='description' register={register} required
                                       helperText={errors?.description?.message} label={t("common.description", {ns: "form/formLabel"})}/>
                            <Typography>{t("question.cost", {ns: "tutorial/tutorial"})}</Typography>
                            <RadioGroupInput ariaLabelledBy="radio-button" name="cost" helperText={errors.cost}
                                             error={!!errors.cost}>
                                {costs.map((cost, index) => (
                                    <RadioInput key={index} name="cost" label={t(cost.label, {ns: "tutorial/tutorial"})}
                                                value={cost.value}
                                                register={register}/>
                                ))}
                            </RadioGroupInput>
                            <Typography>{t("question.difficulty", {ns: "tutorial/tutorial"})}</Typography>
                            <RadioGroupInput ariaLabelledBy="radio-button" name="difficulty" helperText={errors.difficulty}
                                             error={!!errors.difficulty}>
                                {difficulties.map((difficulty, index) => (
                                    <RadioInput key={index} name="difficulty"
                                                label={t(difficulty.label, {ns: "tutorial/tutorial"})}
                                                value={difficulty.value}
                                                register={register}/>
                                ))}
                            </RadioGroupInput>
                            <Typography>{t("question.PEGI", {ns: "tutorial/tutorial"})}</Typography>
                            <RadioGroupInput ariaLabelledBy="radio-button" name="PEGI" helperText={errors.PEGI}
                                             error={!!errors.PEGI}>
                                {PEGIs.map((PEGI, index) => (
                                    <RadioInput key={index} name="PEGI"
                                                label={t(PEGI.label, {ns: "tutorial/tutorial"})}
                                                value={PEGI.value}
                                                register={register}/>
                                ))}
                            </RadioGroupInput>
                        </Stack>
                        <div style={{marginTop: "35px"}}>
                            <CustomButton disabled={loading} variant="contained" type="submit">
                                {t("add", {ns:"glossary"})}
                            </CustomButton>
                        </div>
                    </Box>
                </ModalBox>
            </Modal>
        </>

    )
}

export default FormAddTutorial