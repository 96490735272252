import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
export default function Footer() {
    const {t} = useTranslation();
    const theme = useTheme()

    return (
        <Box style={{
            position: "absolute", bottom: "0", height: "5vh", width:"100%", zIndex: '1', display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center",
       backgroundColor: theme.palette.primary.main }}>
                <Typography sx={{fontSize: "12px", margin: {xs: 1, sm: 1}, color:'#ffffff'}}>
                    {t("all_rights", {ns:"footer/footer"})}
                    <a
                        href="/cgu"
                        target="_blank"
                        style={{color: theme.palette.secondary.main, textDecoration: "none"}}
                    >
                        {t("cgu", {ns: "footer/footer"})}
                    </a>{" "}
                  {/*  {t("contact", {ns: "footer/footer"})}
                    <a
                        href={`mailto:${"contact@tutotou.fr"}`}
                        style={{color: theme.palette.secondary.main, textDecoration: "none"}}
                    >
                        "contact@tutotou.fr"
                    </a>*/}
                </Typography>
        </Box>
    );
}
