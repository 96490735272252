import React, {useContext} from "react";
import {Card, CardContent, Container} from "@mui/material";
import {useTranslation} from "react-i18next";
import Page from "../../components/layout/Page";
import {LayoutContext} from "../../layout/FullLayout";

const Help = () => {
    const {width} = useContext(LayoutContext);
  const { t } = useTranslation("common");
  return (
    <Page sx={{ml:width}} title={t("dashboard")}>
      <Container sx={{ p: 5 }}>
        <Card>
          <CardContent>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default Help;
